export const primaryColor = `rgba(241, 175, 19, 0.5)`

export const linearGradient = `
linear-gradient( 
    -100deg,
    rgba(255,250,150,0.15),
    ${primaryColor} 100%,
    rgba(255,250,150,0.25) 
    );
`

